<template>
  <v-app>
    <v-main>
      <v-container class="pa-0" fluid >
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
